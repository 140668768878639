import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { InternalId } from '@edgebox/data-definition-kit';
import { ClientRemoteEntityRevisionEntitySummary } from '@edgebox/sync-core-rest-client';
import { Dropdown } from 'react-bootstrap';
import { SyncCoreApiContext } from '../../../../../contexts/SyncCoreApiContext';
import withErrorHandler, { ErrorHandler } from '../../../../../foundation/components/WithErrorHandler/WithErrorHandler';
import { SyncCoreFeatureFlagGate } from '../../../../SyncCoreFeatureFlagGate';
import { FEATURE_PROJECT_LANGUAGES_AVAILABLE } from '../../../../../features';

type LanguageType = {
  name: string;
  code: string;
  id: InternalId;
};

type LanguageSelectorProps = {
  handleSelect: (id: InternalId, code: string) => void;
  entity: ClientRemoteEntityRevisionEntitySummary;
  selectedLanguage: string;
} & ErrorHandler;

const LanguageSelector = ({ handleSelect, entity, selectedLanguage, wrapRequest, renderRequest }: LanguageSelectorProps) => {
  const api = useContext(SyncCoreApiContext);

  // Initial entity passed
  const initialEntityRef = useRef(entity);

  const [languages, setLanguages] = useState<LanguageType[]>([]);

  const fetchData = async () => {
    try {
      if (api) {
        const projectId = initialEntityRef.current.project.getId();

        const syndication = api.api.syndication;

        const [projectLanguagesRes, translationsRes] = await wrapRequest(() =>
          Promise.all([
            syndication.projects.getLanguages(projectId),
            syndication.remoteEntityRevisions.getTranslations(initialEntityRef.current.id),
          ])
        );

        // All the languages used within a project
        const projectLanguages = projectLanguagesRes.items;

        // Entity translations
        const translations = translationsRes.items;

        // Default one is not added so we need to add that.
        translations.unshift({
          id: initialEntityRef.current.id,
          language: initialEntityRef.current.language,
          versionId: '12121221',
        });

        const langs: LanguageType[] = [];

        for (const t of translations) {
          if (t.id) {
            const language = projectLanguages.find((l) => l.code === t.language);
            langs.push({
              name: language?.name ?? t.language.toUpperCase(),
              code: language?.code ?? t.language,
              id: t.id,
            });
          }
        }

        setLanguages(langs);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (entity && entity.id) {
      fetchData();
    }
  }, []);

  /**
   * Handles language change.
   */
  const handleLanguageChange = (eventKey: string | null) => {
    const selected = languages.find((item) => item.id === eventKey);
    if (selected) {
      handleSelect(selected.id, selected.code);
    }
  };

  // Language dropdown label.
  const dropdownLabel = useMemo(() => {
    if (languages && selectedLanguage) {
      const selected = languages.filter((language) => language.code === selectedLanguage);
      if (selected && selected.length > 0) {
        return selected[0].name;
      }
      return 'Languages';
    }

    return 'Languages';
  }, [languages, selectedLanguage]);

  return (
    <SyncCoreFeatureFlagGate
      featureName={FEATURE_PROJECT_LANGUAGES_AVAILABLE}
      ifEnabled={() => (
        <div className="mx-3 ">
          <Dropdown onSelect={handleLanguageChange}>
            <Dropdown.Toggle variant="primary" id="language-dropdown" style={{ height: 50, minWidth: 150 }}>
              {renderRequest('throbber')} {dropdownLabel}
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-1">
              {languages.map((item) => (
                <Dropdown.Item key={item.id} eventKey={item.id} active={item.code === selectedLanguage}>
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    />
  );
};

export default withErrorHandler(LanguageSelector);
